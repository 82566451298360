// stores/translations.js
import { defineStore } from 'pinia'
import { useFetch } from '#app'

export const useTranslationStore = defineStore('translations', {
    
  state: () => ({
    translations: {}
  }),

  actions: {
    async fetchTranslations() {
      const { locale } = useI18n()
      const config = useRuntimeConfig()
      const apiUrl = `${config.public.baseApi}/${locale.value}/translations` // cms
      // const apiUrl = `${config.public.appApi}/${locale.value}/translations` // template

      try {
        const { data, error } = await useFetch(apiUrl)
        if (!error.value) {
          this.translations = data.value
        } else {
          console.error('Error fetching translations:', error.value)
        }
      } catch (err) {
        console.error('Failed to fetch translations:', err)
      }
    },

    translate(key) {
      const text = this.translations[key] || key

      // ตรวจสอบว่าเป็น email หรือไม่
      if (key.includes('email.detail')) {
        return `<a href="mailto:${text}">${text}</a>`
      }

      return text
    }
  }
})